import styled from 'styled-components';

export const UserAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`;

export const UserImage = styled.img`
  widows: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
`;

export const UserContent = styled.div`
  padding-left: 15px;
`;

export const UserName = styled.h5`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
  line-height: 1;
  color: ${({ theme }) => theme.color.darkGray};
`;

export const UserDate = styled.p`
  font-size: 16px;
  line-height: 1;
  color: ${({ theme }) => theme.color.gray};
  margin: 0;
`;
