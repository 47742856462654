import styled, { css } from 'styled-components';
import { media } from 'utils';

export const Button = styled.button`
  display: block;
  font-size: 15px;
  padding: 11px 18px;
  color: ${({ isInversed, isLoading, theme }) =>
    isLoading ? 'transparent' : theme.color[isInversed ? 'primary' : 'white']};
  background-color: ${({ isInversed, theme }) =>
    isInversed ? 'transparent' : theme.color.primary};
  border-radius: 100px;
  min-width: 180px;
  border: 2px solid ${(props) => props.theme.color.primary};
  transition: color 0.2s ease, border 0.2s ease, background-color 0.2s ease;
  text-transform: uppercase;
  line-height: 1;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  position: relative;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        color: ${({ isInversed, theme }) =>
          theme.color[isInversed ? 'white' : 'primary']};
        background-color: ${({ isInversed, theme }) =>
          isInversed ? theme.color.primary : 'transparent'};
      }
    `};

  &:focus {
    outline: none;
  }

  ${media.large(css`
    font-size: 16px;
    padding: 12px 20px;
  `)};

  .buttonSpinner {
    position: absolute;
    top: 5px;
    left: 50%;
    margin-left: -15px;
  }
`;
