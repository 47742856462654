import styled from 'styled-components';
import Img from 'gatsby-image';

import { Button as Base } from 'components/common/Button/Button';

export const NewsListItemWrapper = styled.div``;

export const Image = styled(Img)`
  min-height: 250px;
  max-height: 330px;
  margin-bottom: 25px;

  @media (max-width: ${(props) => props.theme.layout.contentWidthSmall}) {
    margin: 0 -${(props) => props.theme.layout.gapMobileX} 25px;
  }
`;

export const Button = styled(Base)`
  margin-top: 25px;
`;
