import styled, { keyframes } from 'styled-components';

const clip = keyframes`
  0% {transform: rotate(0deg) scale(1)}
  50% {transform: rotate(180deg) scale(0.8)}
  100% {transform: rotate(360deg) scale(1)}
`;

export const Spinner = styled.div`
  background: transparent;
  width: ${({ size }) => (size && `${size}px`) || '30px'};
  height: ${({ size }) => (size && `${size}px`) || '30px'};
  border-radius: 100%;
  border: 2px solid;
  border-color: ${({ color, theme }) => color || theme.color.lightGray};
  border-bottom-color: transparent;
  display: inline-block;
  animation: ${clip} 0.75s 0s infinite linear;
  animation-fill-mode: both;
`;
