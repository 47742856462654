import React from 'react';
import PropTypes from 'prop-types';

import { Spinner } from 'components/common/Spinner/Spinner';
import { Button as ButtonStyled } from './ButtonStyles';

export const Button = ({ children, isLoading, ...props }) => (
  <ButtonStyled aria-label="Call to action" isLoading={isLoading} {...props}>
    {children}
    {isLoading && <Spinner className="buttonSpinner" />}
  </ButtonStyled>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
};

Button.defaultProps = {
  isLoading: false,
};
