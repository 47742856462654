import React from 'react';
import PropTypes from 'prop-types';

import { navigate } from 'gatsby';

import { Title, SectionDescription } from 'components/common/Typography';
import { UserAvatar } from 'components/common/UserAvatar/UserAvatar';
import { NewsPropTypes } from 'components/News/News';

import { NewsListItemWrapper, Image, Button } from './NewsListItemStyles';

export const NewsListItem = ({
  excerpt,
  fields,
  frontmatter: { heroImage, title, ...userProps },
  id,
}) => (
  <NewsListItemWrapper key={id}>
    <Image
      alt={title}
      as={heroImage?.childImageSharp?.fluid ? Image : 'img'}
      fluid={heroImage?.childImageSharp?.fluid}
      src={heroImage}
    />
    <UserAvatar {...userProps} />
    <Title>{title}</Title>
    <SectionDescription>{excerpt}</SectionDescription>
    <Button onClick={() => navigate(fields.slug)}>Zobacz więcej</Button>
  </NewsListItemWrapper>
);

export const NewsListItemPropTypes = {
  excerpt: PropTypes.string.isRequired,
  fields: PropTypes.shape({
    slug: PropTypes.string,
  }).isRequired,
  frontmatter: NewsPropTypes.isRequired,
  id: PropTypes.string.isRequired,
};

NewsListItem.propTypes = NewsListItemPropTypes;
