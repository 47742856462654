import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';

import { FluidImagePropTypes } from 'types';
import { withMedia } from 'utils';
import { Transition, animated } from 'react-spring/renderprops';

import { PageSection } from 'components/common/PageSection/PageSection';
import { InfiniteScroll } from 'components/common/InfiniteScroll/InfiniteScroll';
import { Image } from 'components/common/Image/Image';
import { Fade } from 'components/common/Animations';
import { NewsListItem, NewsListItemPropTypes } from './NewsListItem';
import { NewsListWrapper, SecondaryHeader } from './NewsListStyles';

const renderItems = (items) => (itemsToDisplay) => {
  const reducedItems = items.reduce((total, item, index) => {
    if (itemsToDisplay >= index + 1) {
      total.push(item);
    }

    return total;
  }, []);

  return (
    <Transition
      enter={{ opacity: 1, transform: 0 }}
      from={{ opacity: 0, transform: 150 }}
      initial={null}
      items={reducedItems}
      keys={({ node }) => node.id}
      leave={{ opacity: 0, transform: 150 }}
      native
    >
      {({ node }) => ({ opacity, transform }) => (
        <animated.div
          style={{
            opacity,
            transform: transform.interpolate((y) => `translateY(${y}px)`),
          }}
        >
          <NewsListItem {...node} />
        </animated.div>
      )}
    </Transition>
  );
};

export const NewsList = withMedia(({ image, isMatched, items, title }) => (
  <>
    {isMatched && (
      <Image
        alt={title}
        as={image?.childImageSharp?.fluid ? Image : 'img'}
        fluid={image?.childImageSharp?.fluid}
        src={image}
      />
    )}
    <PageSection>
      {isMatched && (
        <Fade>
          <SecondaryHeader isBolded isUnderlined>
            {title}
          </SecondaryHeader>
        </Fade>
      )}
      {!!items.length && (
        <Fade>
          <NewsListWrapper>
            <InfiniteScroll
              itemsCount={items.length}
              renderItems={renderItems(items)}
            />
          </NewsListWrapper>
        </Fade>
      )}
    </PageSection>
  </>
));

export const NewsListItemsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    node: PropTypes.shape(NewsListItemPropTypes).isRequired,
  })
);

export const NewsListPagePropTypes = {
  image: PropTypes.oneOfType([FluidImagePropTypes, PropTypes.string])
    .isRequired,
  title: PropTypes.string.isRequired,
};

NewsList.propTypes = {
  isMatched: PropTypes.bool,
  items: NewsListItemsPropTypes,
  ...NewsListPagePropTypes,
};

NewsList.defaultProps = {
  items: [],
};

export const newsListItemsFragment = graphql`
  fragment NewsListItemsFragment on MarkdownRemarkConnection {
    edges {
      node {
        excerpt(pruneLength: 400)
        id
        fields {
          slug
        }
        frontmatter {
          author
          avatarImage {
            publicURL
          }
          title
          templateKey
          date(formatString: "DD MMMM, YYYY")
          heroImage {
            childImageSharp {
              fluid(maxWidth: 700) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`;

export const newsListPageFragment = graphql`
  fragment NewsListPageFragment on MarkdownRemark {
    frontmatter {
      title
      image {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`;
