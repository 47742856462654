import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';

import { FluidImagePropTypes } from 'types';

import {
  UserAvatar,
  UserAvatarPropTypes,
} from 'components/common/UserAvatar/UserAvatar';
import { MainHeader } from 'components/common/Typography';
import { HTMLContent } from 'components/common/HTMLContent/HTMLContent';
import { PageSection } from 'components/common/PageSection/PageSection';
import { Image } from 'components/common/Image/Image';
import { Fade } from 'components/common/Animations';
import { PageContent } from './NewsStyles';

export const News = ({ content, heroImage, isHTML, title, ...userProps }) => (
  <>
    <Image
      alt={title}
      as={heroImage?.childImageSharp?.fluid ? Image : 'img'}
      fluid={heroImage?.childImageSharp?.fluid}
      src={heroImage}
    />
    <PageSection>
      <PageContent>
        <Fade>
          <UserAvatar {...userProps} />
          <MainHeader>{title}</MainHeader>
        </Fade>
        <Fade>
          <HTMLContent content={content} isHTML={isHTML} />
        </Fade>
      </PageContent>
    </PageSection>
  </>
);

export const NewsPropTypes = PropTypes.shape({
  heroImage: PropTypes.oneOfType([FluidImagePropTypes, PropTypes.string])
    .isRequired,
  ...UserAvatarPropTypes,
  title: PropTypes.string.isRequired,
});

News.propTypes = {
  content: PropTypes.node.isRequired,
  ...NewsPropTypes,
  isHTML: PropTypes.bool,
};

News.defaultProps = {
  isHTML: false,
};

export const newsPageFragment = graphql`
  fragment NewsPageFragment on MarkdownRemark {
    frontmatter {
      date(formatString: "DD MMMM, YYYY")
      author
      avatarImage {
        publicURL
      }
      title
      heroImage {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`;
