import React from 'react';
import PropTypes from 'prop-types';

import { ImagePropTypes } from 'types';

import {
  UserAvatarWrapper,
  UserImage,
  UserContent,
  UserName,
  UserDate,
} from './UserAvatarStyles';

export const UserAvatar = ({ author, avatarImage, date }) => (
  <UserAvatarWrapper>
    <UserImage alt={author} src={avatarImage?.publicURL || avatarImage} />
    <UserContent>
      <UserName>{author}</UserName>
      <UserDate>{date}</UserDate>
    </UserContent>
  </UserAvatarWrapper>
);

export const UserAvatarPropTypes = {
  author: PropTypes.string.isRequired,
  avatarImage: PropTypes.oneOfType([ImagePropTypes, PropTypes.string])
    .isRequired,
  date: PropTypes.string.isRequired,
};

UserAvatar.propTypes = UserAvatarPropTypes;
