import styled, { css } from 'styled-components';
import { media } from 'utils';

import { SecondaryHeader as Base } from 'components/common/Typography';

export const NewsListWrapper = styled.section`
  max-width: ${(props) => props.theme.layout.contentWidthSmall};
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-gap: 50px;
  margin-bottom: 10px;

  @media (max-width: ${(props) => props.theme.layout.contentWidthSmall}) {
    margin-top: -${(props) => props.theme.layout.gapMobileY};
  }

  ${media.large(css`
    grid-gap: 100px;
  `)};
`;

export const SecondaryHeader = styled(Base)`
  max-width: ${(props) => props.theme.layout.contentWidthSmall};
  margin-left: auto;
  margin-right: auto;
`;
